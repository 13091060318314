import { useState, useEffect } from "react";
import "./selectTextInput.css";

const SelectText = ({
  variable,
  setVariable,
  fieldName,
  options,
  compColor,
}) => {
  const [dropdownMenu, setDropDownMenu] = useState({
    0: false,
    1: false,
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setDropDownMenu((prevState) => {
        return Object.keys(prevState).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
      });
    }
  };

  const toggleDropdown = (dropdownId) => {
    setDropDownMenu((prev) => ({
      ...prev,
      [dropdownId]: !prev[dropdownId],
    }));
  };

  const handleChange = (val) => {
    setVariable((prevState) => ({
      ...prevState,
      [fieldName]: val,
    }));
  };

  function toCapitalize(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const uniqueClass = `dropdown-${fieldName}`;

  const arrowStyle = `
  .${uniqueClass}  .dropdown-menu::after {
      background-image: url('https://img.icons8.com/material-rounded/${compColor}/96/sort-down.png');
    }`;

  return (
    <>
      <style>{arrowStyle}</style>
      <div className={`wrapper-select-text ${uniqueClass}`}>
        <div className="dropdown-menu">
          <input
            style={{
              border: `1px solid #${compColor}`,
            }}
            type="text"
            id={`${fieldName}`}
            name={`${fieldName}`}
            onChange={handleChange}
            value={variable[fieldName]}
            onClick={() => {
              toggleDropdown(1);
              if (dropdownMenu[0] === true) {
                toggleDropdown(0);
              }
            }}
            readOnly
            required
          />

          {dropdownMenu[1] && (
            <ul className="form-dropdown-list">
              {options.map((val) => (
                <li
                  style={{ "--hoverColor": `#${compColor}` }}
                  onClick={() => {
                    setVariable((prevState) => ({
                      ...prevState,
                      [fieldName]: val,
                    }));
                    toggleDropdown(1);
                  }}
                >
                  {val}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
export default SelectText;
