import Red from "../style/styleRed";
import "./volunteerInterestRequest.css";
import { useState, useEffect } from "react";
import { useRef } from "react";
import baseUrl from "../baseUrl";

const VolunteerInterestRequest = () => {
  const [volunteer, setVolunteer] = useState({
    name: "",
    email: "",
    volunteerRole: "",
    other: "",
    request: "",
  });
  const [dropdownClosed, setDropdownClosed] = useState(true);
  const dropdownRef = useRef(null);
  const [showSentMessage, setShowSentMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState("");

  const roles = [
    "Board member",
    "Fundraiser",
    "Grant application writer",
    "Other",
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownClosed(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVolunteer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setDropdownClosed(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch(`${baseUrl}/api/send/send-email-volunteer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        name: volunteer.name,
        email: volunteer.email,
        volunteerRole: volunteer.volunteerRole,
        other: volunteer.other,
        request: volunteer.request,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      })
      .then((data) => {
        console.log("Success:", data);
        setShowSentMessage(true);
        setShowSuccessMessage(
          "Thank you for submitting your request! We will be in touch with you shortly."
        );
        setVolunteer({
          name: "",
          email: "",
          volunteerRole: "",
          other: "",
          request: "",
        });
      })
      .catch((error) => {
        console.error("Error occurred:", error.message);
        setShowSentMessage(true);
        setShowSuccessMessage(
          "Something went wrong... Please try again or email us at info@bcgap.org."
        );
      });
  };

  return (
    <div className="interest">
      <div
        className="sent-message"
        style={{ display: showSentMessage ? "flex" : "none" }}
      >
        <div>{showSuccessMessage}</div>
        <button onClick={() => setShowSentMessage(false)}>OK</button>
      </div>
      <div className="interest-subheader">Get in touch with us!</div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="full-name">
          Full Name <Red> *</Red>
        </label>
        <input
          type="text"
          id="full-name"
          name="name"
          value={volunteer.name}
          onChange={handleChange}
          required
        />
        <label htmlFor="email">
          Email <Red> *</Red>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={volunteer.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="volunteer-opportunity">
          Volunteering opportunity <Red> *</Red>
        </label>
        <div className="menu-dropdown-container">
          <input
            type="text"
            className="menu-dropdown-select"
            onClick={() => setDropdownClosed(!dropdownClosed)}
            value={volunteer.volunteerRole}
            placeholder="Select an option: "
            required
            readOnly
          ></input>
          <div
            className="menu-dropdown-all-options"
            ref={dropdownRef}
            style={{ display: dropdownClosed ? "none" : "block" }}
          >
            {roles.map((role, index) => (
              <div
                key={index}
                className="menu-dropdown-option"
                onClick={() => {
                  setVolunteer((prevState) => ({
                    ...prevState,
                    volunteerRole: role,
                  }));
                  setDropdownClosed(!dropdownClosed);
                }}
              >
                {role}
              </div>
            ))}
          </div>
        </div>
        <label htmlFor="full-name">If you selected Other:</label>
        <input
          type="text"
          id="other-role"
          name="other"
          value={volunteer.other}
          onChange={handleChange}
        />
        <label htmlFor="request">
          Message <Red> *</Red>
        </label>
        <textarea
          type="text"
          id="request"
          name="request"
          rows="5"
          value={volunteer.request}
          onChange={handleChange}
          required
        />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default VolunteerInterestRequest;
