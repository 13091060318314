import { useState, useEffect, useRef } from "react";
import "./homeHeroBanner.css";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedVideo } from "@cloudinary/react";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { format } from "@cloudinary/url-gen/actions/delivery";

const HomeHeroBanner = ({ videoLoaded, setVideoLoaded }) => {
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [sectionHeight, setSectionHeight] = useState(0);
  const [ratio, setRatio] = useState(false);

  {
    /*
      useEffect(() => {
    if (divRef.current) {
      var tempW = divRef.current.getBoundingClientRect().width;
      var tempH = divRef.current.getBoundingClientRect().height;
      setWidth(tempW);
      setSectionHeight(tempH);
      if (tempW / tempH <= 1.6) {
        setRatio(true);
      }
    }
  }, []);    
    */
  }

  // Initialize Cloudinary & Define Video Here
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dughixn4l",
    },
  });

  const video = cld
    .video("c6svfjbl6qdrnsmwp90x")
    .resize(scale())
    .delivery(quality("auto:good"))
    .delivery(format("auto"));

  const handleVideoLoaded = () => {
    setTimeout(() => {
      setVideoLoaded(true);
    }, 1000);
  };

  return (
    <div className="hero-section">
      <div className="home-hero-overlay"></div>
      <div className="hero-image">
        <AdvancedVideo
          cldVid={video}
          autoPlay
          muted
          playsInline
          loop
          preload="none"
          poster="/video/home/fallback.jpg"
          onCanPlay={handleVideoLoaded}
        />
      </div>
      <div className="hero-heading">
        <div className="hero-header">
          <div
            className="get-width"
            ref={divRef}
            style={{ width: "100%", height: "100%" }}
          >
            Bent Christy <br />
            Graduate <br /> Assistance <br /> Program
          </div>

          <div>
            <a href="/donate-bent-christy-graduate-assistance-program">
              <button className="btn-donate">Donate</button>
            </a>
            <a href="/#bcgap-apply-for-repayment-and-scholarship">
              <button className="btn-apply">Apply</button>
            </a>
          </div>
        </div>
        <div className="hero-subheader">
          <div className="subheader-group">
            <span className="hero-sub-word">being </span>{" "}
            <span className="hero-sub-word">the change</span>{" "}
            <span className="hero-sub-word"> we need</span>{" "}
            <span className="hero-sub-word"> to see</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeroBanner;
