import "./App.css";
import { useState, useEffect } from "react";
import PageHome from "./homePage/PageHome";
import PageAbout from "./aboutPages/PageAbout";
import PageDonate from "./getInvlovesPages/PageDonate";
import PageForUndergrads from "./applicationPages/PageForUndergrads-2";
import PageForParents from "./applicationPages/PageForParents-2";
import PageVolunteer from "./getInvlovesPages/PageVolunteer";
import PageForHighSchoolStudents from "./applicationPages/PageForHighSchoolStudents-2";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import HomeNavigation from "./navigation-footer/navigation";
import Footer from "./navigation-footer/footer";
import BcgapStore from "./bcgapStore/bcgapStore";
import BcgapCart from "./bcgapStore/bcgapCart";
import ContactUs from "./aboutPages/PageContact";
import PageTeam from "./aboutPages/PageTeam";
import PageBecomePartner from "./getInvlovesPages/PageBecomePartner";
import PageNewsStories from "./newsPages/PageNewsStories";
import PageAdmin from "./adminPages/PageAdminAccess";
import PageAdminIndividualHighSchool from "./adminPages/PageAdminIndividualHighSchool";
import LoginPage from "./authPages/login";
import ResetPassword from "./authPages/resetPassword";
import Privileges from "./adminPages/PagePrivileges";
import PageArchive from "./adminPages/PageArchive";
import Registration from "./authPages/register";
import { PrivateRoute } from "./privateRoute";
import { PrivateRouteAdmin } from "./privateRoute";
import { PrivateRouteAdminNav } from "./privateRoute";
import { UserProvider, useUser } from "./userContext";
import Page404 from "./page404";
import PageAdminIndividualUndergrad from "./adminPages/PageAdminIndividualUndergrad";
import PageAdminIndividualParent from "./adminPages/PageAdminIndividualParents";
import IndividualTeamMember from "./aboutPages/individualTeamMember";
import PagePrivacyPolicy from "./privacyPolicy";
import AdminAccessNav from "./navigation-footer/adminAccessNav";
import StorePrintful from "./bcgapStore/storePintful";
import StoreBonfire from "./bcgapStore/bcgapStoreBonfire";
import ThankYou from "./getInvlovesPages/successPage";

const App = () => {
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [donateImagesLoaded, setDonateImagesLoaded] = useState(false);
  const isHomePage = location.pathname === "/";
  const isDonatePage =
    location.pathname === "/donate-bent-christy-graduate-assistance-program";

  useEffect(() => {
    console.log("donateImagesLoaded", donateImagesLoaded);
  }, [donateImagesLoaded]);

  useEffect(() => {
    console.log("Home Img loaded", videoLoaded);
  }, [videoLoaded]);

  return (
    <>
      <UserProvider>
        {isHomePage && !videoLoaded ? (
          <div className="loading">
            <img src="/icons/loading2.gif" alt="Loading..." />
          </div>
        ) : null}

        {isDonatePage && !donateImagesLoaded ? (
          <div className="loading">
            <img src="/icons/loading2.gif" alt="Loading..." />
          </div>
        ) : null}

        <div
          className="App"
          style={{
            display:
              (isHomePage && videoLoaded) ||
              (isDonatePage && donateImagesLoaded) ||
              (!isHomePage && !isDonatePage)
                ? "block"
                : "none",
          }}
        >
          <PrivateRoute>
            <AdminAccessNav />
          </PrivateRoute>
          <HomeNavigation />
          <Routes>
            <Route
              path="/"
              element={
                <PageHome
                  videoLoaded={videoLoaded}
                  setVideoLoaded={setVideoLoaded}
                />
              }
            />
            <Route
              path="/about-bent-christy-graduate-assistance-program"
              element={<PageAbout />}
            />
            <Route
              path="/donate-bent-christy-graduate-assistance-program"
              element={
                <PageDonate
                  donateImagesLoaded={donateImagesLoaded}
                  setDonateImagesLoaded={setDonateImagesLoaded}
                />
              }
            />

            <Route
              path="/bent-christy-graduate-assistance-program-donate-success"
              element={<ThankYou />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-for-college-students"
              element={<PageForUndergrads />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-for-parents"
              element={<PageForParents />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-for-high-school-students"
              element={<PageForHighSchoolStudents />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-volunteer-opportunities"
              element={<PageVolunteer />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-bcgap-store"
              element={<BcgapStore />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-contact-us"
              element={<ContactUs />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-our-team"
              element={<PageTeam />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-our-team-member/:id"
              element={<IndividualTeamMember />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-become-a-partner"
              element={<PageBecomePartner />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-news-stories"
              element={<PageNewsStories />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-privacy-policy"
              element={<PagePrivacyPolicy />}
            />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route
              path="/admin-access"
              element={
                <PrivateRoute>
                  <PageAdmin />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-access-archive"
              element={
                <PrivateRoute>
                  <PageArchive />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-access-privileges"
              element={
                <PrivateRouteAdmin>
                  <Privileges />
                </PrivateRouteAdmin>
              }
            />

            <Route
              path="/show-applicant/high-school/:id/:name"
              element={
                <PrivateRoute>
                  <PageAdminIndividualHighSchool />
                </PrivateRoute>
              }
            />
            <Route
              path="/show-applicant/undergrad/:id/:name"
              element={
                <PrivateRoute>
                  <PageAdminIndividualUndergrad />
                </PrivateRoute>
              }
            />
            <Route
              path="/show-applicant/parent/:id/:name"
              element={
                <PrivateRoute>
                  <PageAdminIndividualParent />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Page404 />} />
          </Routes>

          <Footer />
        </div>
      </UserProvider>
    </>
  );
};

export default App;

{
  /* 
  
(1) Add backup to the db;
(2) Add Thank-you email to the Donation;
(3) Add form validation NOT NULL for all forms (test using POSTMAN for backend);

*/
}
