import "./bcgapStore.css";
import { useState, useEffect } from "react";
import bcgapStoreItems from "./bcgapStoreItems";

const BcgapStore = () => {
  const [countCart, setCountCart] = useState(0);
  const [itemQuantities, setItemQuantities] = useState(
    bcgapStoreItems.map(() => 1)
  );
  const [cart, setCart] = useState([]);
  const [itemsInStore, setItemsInStore] = useState(0);

  useEffect(() => {
    const tabs = document.querySelectorAll(".shop-tab");
    tabs[0].style.setProperty("--before-width", "100%");
  }, []);

  const shopMugs = [
    {
      image: "./store-images/bonfire/bonfire6.jpg",
      title: "Black Two-Tone Ceramic Mug",
      link: "https://www.bonfire.com/bcgap10online-store/",
    },
    {
      image: "./store-images/bonfire/bonfire20.jpg",
      title: "Light Blue Two-Tone Ceramic Mug",
      link: "https://www.bonfire.com/bcgap10online-store/",
    },
    {
      image: "./store-images/bonfire/bonfire21.jpg",
      title: "Green Two-Tone Ceramic Mug",
      link: "https://www.bonfire.com/bcgap10online-store/",
    },
    {
      image: "./store-images/bonfire/bonfire23.jpg",
      title: "Orange Two-Tone Ceramic Mug",
      link: "https://www.bonfire.com/bcgap10online-store/",
    },
    {
      image: "./store-images/bonfire/bonfire24.jpg",
      title: "Red Two-Tone Ceramic Mug",
      link: "https://www.bonfire.com/bcgap10online-store/",
    },
    {
      image: "./store-images/bonfire/bonfire35.jpg",
      title: "Coffee Mug",
      link: "https://www.bonfire.com/bcgap10online-store-11/",
    },
    {
      image: "./store-images/bonfire/bonfire36.jpg",
      title: "Coffee Mug",
      link: "https://www.bonfire.com/bcgap10online-store-12/",
    },
  ];

  const shopShirts = [
    {
      image: "./store-images/bonfire/bonfire11.jpg",
      title: "Classic Unisex Tank Top",
      link: "https://www.bonfire.com/bcgap10online-store-7/",
    },
    {
      image: "./store-images/bonfire/bonfire12.jpg",
      title: "Classic Unisex Tank Top",
      link: "https://www.bonfire.com/bcgap10online-store-7/",
    },
    {
      image: "./store-images/bonfire/bonfire13.jpg",
      title: "Classic Unisex Tank Top",
      link: "https://www.bonfire.com/bcgap10online-store-7/",
    },
    {
      image: "./store-images/bonfire/bonfire14.jpg",
      title: "Classic Unisex Tank Top",
      link: "https://www.bonfire.com/bcgap10online-store-7/",
    },
    {
      image: "./store-images/bonfire/bonfire2.jpg",
      title: "Black Quarter-Zip Windbreaker",
      link: "https://www.bonfire.com/bcgap10online-store-6/",
    },
    {
      image: "./store-images/bonfire/bonfire15.jpg",
      title: "Black Quarter-Zip Windbreaker",
      link: "https://www.bonfire.com/bcgap10online-store-6/",
    },
    {
      image: "./store-images/bonfire/bonfire8.jpg",
      title: "Dark Heather Grey Premium Unisex Tee",
      link: "https://www.bonfire.com/bcgap10online-store-1/",
    },
    {
      image: "./store-images/bonfire/bonfire9.jpg",
      title: "Stone Grey Premium Unisex Tee",
      link: "https://www.bonfire.com/bcgap10online-store-2/",
    },
    {
      image: "./store-images/bonfire/bonfire19.jpg",
      title: "Kelly Green Premium Unisex Tee",
      link: "https://www.bonfire.com/bcgap10online-store-2/",
    },
    {
      image: "./store-images/bonfire/bonfire18.jpg",
      title: "Cardinal Premium Unisex Tee",
      link: "https://www.bonfire.com/bcgap10online-store-2/",
    },
    {
      image: "./store-images/bonfire/bonfire17.jpg",
      title: "Purple Rush Premium Unisex Tee",
      link: "https://www.bonfire.com/bcgap10online-store-2/",
    },
    {
      image: "./store-images/bonfire/bonfire16.jpg",
      title: "Royal Blue Premium Unisex Tee",
      link: "https://www.bonfire.com/bcgap10online-store-2/",
    },
    {
      image: "./store-images/bonfire/bonfire25.jpg",
      title: "Allmade Organic French Terry Crewneck Sweatshirt",
      link: "https://www.bonfire.com/bcgap10online-store-8/",
    },
    {
      image: "./store-images/bonfire/bonfire26.jpg",
      title: "Allmade Organic French Terry Crewneck Sweatshirt",
      link: "https://www.bonfire.com/bcgap10online-store-8/",
    },
    {
      image: "./store-images/bonfire/bonfire27.jpg",
      title: "Allmade Organic French Terry Pullover Hoodie",
      link: "https://www.bonfire.com/bcgap10online-store-9/",
    },
    {
      image: "./store-images/bonfire/bonfire28.jpg",
      title: "Allmade Organic French Terry Pullover Hoodie",
      link: "https://www.bonfire.com/bcgap10online-store-9/",
    },
    {
      image: "./store-images/bonfire/bonfire29.jpg",
      title: "Allmade Organic French Terry Pullover Hoodie",
      link: "https://www.bonfire.com/bcgap10online-store-9/",
    },
    {
      image: "./store-images/bonfire/bonfire30.jpg",
      title: "Allmade Organic French Terry Pullover Hoodie",
      link: "https://www.bonfire.com/bcgap10online-store-9/",
    },
    {
      image: "./store-images/bonfire/bonfire31.jpg",
      title: "Allmade Organic French Terry Pullover Hoodie",
      link: "https://www.bonfire.com/bcgap10online-store-9/",
    },
    {
      image: "./store-images/bonfire/bonfire37.jpg",
      title: "Midweight Fleece Joggers",
      link: "https://www.bonfire.com/bcgap10online-store-13/",
    },
    {
      image: "./store-images/bonfire/bonfire38.jpg",
      title: "Midweight Fleece Joggers",
      link: "https://www.bonfire.com/bcgap10online-store-13/",
    },
    {
      image: "./store-images/bonfire/bonfire39.jpg",
      title: "Midweight Fleece Joggers",
      link: "https://www.bonfire.com/bcgap10online-store-13/",
    },
    {
      image: "./store-images/bonfire/bonfire40.jpg",
      title: "Midweight Fleece Joggers",
      link: "https://www.bonfire.com/bcgap10online-store-13/",
    },
    {
      image: "./store-images/bonfire/bonfire41.jpg",
      title: "Midweight Fleece Joggers",
      link: "https://www.bonfire.com/bcgap10online-store-13/",
    },
    {
      image: "./store-images/bonfire/bonfire42.jpg",
      title: "Printed Eco Baseball Cap",
      link: "https://www.bonfire.com/bcgap10online-store-14/",
    },
    {
      image: "./store-images/bonfire/bonfire43.jpg",
      title: "Printed Eco Baseball Cap",
      link: "https://www.bonfire.com/bcgap10online-store-14/",
    },
    {
      image: "./store-images/bonfire/bonfire44.jpg",
      title: "Printed Eco Baseball Cap",
      link: "https://www.bonfire.com/bcgap10online-store-14/",
    },
    {
      image: "./store-images/bonfire/bonfire45.jpg",
      title: "Printed Eco Baseball Cap",
      link: "https://www.bonfire.com/bcgap10online-store-14/",
    },
    {
      image: "./store-images/bonfire/bonfire46.jpg",
      title: "Printed Eco Baseball Cap",
      link: "https://www.bonfire.com/bcgap10online-store-14/",
    },
  ];

  const shopBags = [
    {
      image: "./store-images/bonfire/bonfire4.jpg",
      title: "White Camo Day Tripper Duffel Bag",
      link: "https://www.bonfire.com/bcgap10online-store-3/",
    },
    {
      image: "./store-images/bonfire/bonfire7.jpg",
      title: "Black Camo Day Tripper Duffel Bag",
      link: "https://www.bonfire.com/bcgap-online-store/",
    },
    {
      image: "./store-images/bonfire/bonfire32.jpg",
      title: "Premium Tote Bag",
      link: "https://www.bonfire.com/bcgap10online-store-15/",
    },
    {
      image: "./store-images/bonfire/bonfire33.jpg",
      title: "Premium Tote Bag",
      link: "https://www.bonfire.com/bcgap10online-store-15/",
    },
    {
      image: "./store-images/bonfire/bonfire34.jpg",
      title: "Premium Tote Bag",
      link: "https://www.bonfire.com/bcgap10online-store-15/",
    },
    {
      image: "./store-images/bonfire/bonfire51.jpg",
      title: "Organic Cotton Tote Bag",
      link: "https://www.bonfire.com/bcgap10online-store-17/",
    },
    {
      image: "./store-images/bonfire/bonfire52.jpg",
      title: "Organic Cotton Tote Bag",
      link: "https://www.bonfire.com/bcgap10online-store-19/",
    },
    {
      image: "./store-images/bonfire/bonfire53.jpg",
      title: "Large Organic Cotton Tote Bag",
      link: "https://www.bonfire.com/bcgap10online-store-18/",
    },
  ];

  const shopMisc = [
    {
      image: "./store-images/bonfire/bonfire49.jpg",
      title: "Cotton Canvas Apron",
      link: "https://www.bonfire.com/bcgap10online-store-5/",
    },
    {
      image: "./store-images/bonfire/bonfire47.jpg",
      title: "Cotton Canvas Apron",
      link: "https://www.bonfire.com/bcgap10online-store-5/",
    },
    {
      image: "./store-images/bonfire/bonfire48.jpg",
      title: "Cotton Canvas Apron",
      link: "https://www.bonfire.com/bcgap10online-store-5/",
    },
    {
      image: "./store-images/bonfire/bonfire50.jpg",
      title: "Silver Cotton Canvas Apron",
      link: "https://www.bonfire.com/bcgap10online-store-5/",
    },
    {
      image: "./store-images/bonfire/bonfire5.jpg",
      title: "White Spiral Notebook",
      link: "https://www.bonfire.com/bcgap10online-store-4/",
    },
  ];

  const storeItemsToSell = [shopMugs, shopShirts, shopBags, shopMisc];

  const handleShowStoreItems = (item) => {
    setItemsInStore(item);
    const tabs = document.querySelectorAll(".shop-tab");
    tabs.forEach((tab) => {
      tab.style.setProperty("--before-width", "0%");
    });

    if (tabs[item]) {
      tabs[item].style.setProperty("--before-width", "100%");
    }
  };

  return (
    <div className="store-section">
      <div className="header"> Shop BCGAP to end college debt!</div>
      <div className="bonfire-logo">
        <img src="./store-images/bonfire/bonfire-logo.jpg" alt="bonfire-logo" />
      </div>
      <div className="shop-tabs">
        <div className="shop-tab-header">CATEGORIES</div>
        <div className="shop-tab" onClick={() => handleShowStoreItems(0)}>
          Mugs
        </div>
        <div className="shop-tab" onClick={() => handleShowStoreItems(1)}>
          Clothing
        </div>
        <div className="shop-tab" onClick={() => handleShowStoreItems(2)}>
          Bags
        </div>
        <div className="shop-tab" onClick={() => handleShowStoreItems(3)}>
          Miscellaneous
        </div>
      </div>
      <div className="store">
        <div className="products">
          {storeItemsToSell[itemsInStore].map((item, index) => (
            <div key={index} className="store-item-card">
              <div>
                <div className="store-item-image">
                  <img
                    src={`${item.image}`}
                    style={itemsInStore === 0 ? {} : {}}
                    alt="card-item-image"
                  />
                </div>
                <div className="store-item-description">
                  <div className="store-item-title">{item.title}</div>
                </div>
              </div>
              <div className="store-card-product-link">
                <a href={item.link} target="_blank">
                  <img
                    src="https://img.icons8.com/ios-filled/facc00/100/circled-up-right-2.png"
                    alt="circled-up-right-2"
                  />
                  <span className="link-explore">
                    EXPLORE ON <br /> BONFIRE
                  </span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BcgapStore;
