import "./PagePrivileges.css";
import WelcomeAdmin from "./welcomeAdmin";
import { useState, useEffect } from "react";
import { useUser } from "../userContext";

const Privileges = () => {
  const [users, setUsers] = useState("");
  const [usersRoles, setUsersRoles] = useState([]);
  const { currentUser } = useUser();

  const baseUrl =
    process.env.REACT_APP_ENV === "production" ? "" : "http://localhost:4010";

  useEffect(() => {
    fetchUsers();
  }, []);

  let csrfToken = null;

  async function getCsrfToken() {
    if (!csrfToken) {
      const response = await fetch(`${baseUrl}/api/csrf-token`, {
        credentials: "include",
      });
      const data = await response.json();
      csrfToken = data.csrfToken;
    }
    return csrfToken;
  }

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/auth/admin-access/get-users`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error("Failed to fetch users:", response.statusText);
        return;
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleRoleChange = (roleUpdate) => {
    setUsersRoles((prevRoles) => {
      // Check if the user already exists in the roles array
      const existingUserIndex = prevRoles.findIndex(
        (user) => user.id === roleUpdate.id
      );

      if (existingUserIndex !== -1) {
        // User exists, update their role
        const updatedRoles = prevRoles.map((user, index) =>
          index === existingUserIndex
            ? { ...user, choice: roleUpdate.choice }
            : user
        );
        return updatedRoles;
      } else {
        const updatedRoles = [...prevRoles, roleUpdate];
        return updatedRoles;
      }
    });
  };

  const handleSaveRole = async (user_id) => {
    const currentUser = usersRoles.find((user) => user.id === user_id);
    try {
      const csrfToken = await getCsrfToken();
      const response = await fetch(`${baseUrl}/api/auth/manage-privileges`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify(currentUser),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        fetchUsers();
      } else {
        console.error("Failed to fetch users:", response.statusText);
        return;
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDeleteUser = async (user_id) => {
    try {
      const csrfToken = await getCsrfToken();
      const response = await fetch(
        `${baseUrl}/api/auth/manage-privileges/delete-user/${user_id}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        fetchUsers();
      } else {
        console.error("Failed to delete the user:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting the user:", error);
    }
  };

  return (
    <>
      <div className="privilages-page">
        <div className="privilages-container">
          <h1> Grant & Revoke User Privileges </h1>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Name</th> <th>Email </th>
                  <th>Role Assigned</th>
                  <th>Change Role</th>
                  <th>Save Role</th>
                  <th>Delete User</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user) => (
                    <tr>
                      <td>
                        {user.firstName} {user.lastName}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.role && user.role.toUpperCase()}</td>
                      <td>
                        {" "}
                        <div className="role-group">
                          <label htmlFor="admin">Admin</label>
                          <input
                            type="radio"
                            name={`role-${user.user_id}`}
                            value="admin"
                            onChange={(event) =>
                              handleRoleChange({
                                id: user.user_id,
                                choice: "admin",
                              })
                            }
                          />
                        </div>
                        <div className="role-group">
                          <label htmlFor="employee">Employee</label>
                          <input
                            type="radio"
                            name={`role-${user.user_id}`}
                            value="employee"
                            onChange={(event) =>
                              handleRoleChange({
                                id: user.user_id,
                                choice: "employee",
                              })
                            }
                          />
                        </div>
                        <div className="role-group">
                          <label htmlFor="volunteer">Volunteer</label>
                          <input
                            type="radio"
                            name={`role-${user.user_id}`}
                            value="volunteer"
                            onChange={(event) =>
                              handleRoleChange({
                                id: user.user_id,
                                choice: "volunteer",
                              })
                            }
                          />
                        </div>
                      </td>
                      <td className="save-changes">
                        <button
                          onClick={(event) => {
                            handleSaveRole(user.user_id);
                          }}
                        >
                          SAVE
                        </button>
                      </td>
                      <td className="delete-user">
                        <button
                          onClick={(event) => {
                            handleDeleteUser(user.user_id);
                          }}
                        >
                          DELETE
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privileges;
