const bcgapStoreItems = [
  {
    image: "./store-images/bonfire/bonfire1.jpg",
    title: "Sport Grey Classic Unisex Tank Top",
    link: "https://www.bonfire.com/bcgap10online-store-7/",
  },
  {
    image: "./store-images/bonfire/bonfire2.jpg",
    title: "Black Quarter-Zip Windbreaker",
    link: "https://www.bonfire.com/bcgap10online-store-6/",
  },
  {
    image: "./store-images/bonfire/bonfire3.jpg",
    title: "Silver Cotton Canvas Apron",
    link: "https://www.bonfire.com/bcgap10online-store-5/",
  },
  {
    image: "./store-images/bonfire/bonfire4.jpg",
    title: "White Camo Day Tripper Duffel Bag",
    link: "https://www.bonfire.com/bcgap10online-store-3/",
  },
  {
    image: "./store-images/bonfire/bonfire5.jpg",
    title: "White Spiral Notebook",
    link: "https://www.bonfire.com/bcgap10online-store-4/",
  },
  {
    image: "./store-images/bonfire/bonfire6.jpg",
    title: "Black Two-Tone Ceramic Mug",
    link: "https://www.bonfire.com/bcgap10online-store/",
  },
  {
    image: "./store-images/bonfire/bonfire7.jpg",
    title: "Black Camo Day Tripper Duffel Bag",
    link: "https://www.bonfire.com/bcgap-online-store/",
  },
  {
    image: "./store-images/bonfire/bonfire8.jpg",
    title: "Dark Heather Grey Premium Unisex Tee",
    link: "https://www.bonfire.com/bcgap10online-store-1/",
  },

  {
    image: "./store-images/bonfire/bonfire9.jpg",
    title: "Stone Grey Premium Unisex Tee",
    link: "https://www.bonfire.com/bcgap10online-store-2/",
  },
];

export default bcgapStoreItems;
