import "./homeSection2.css";

const HomeSectionTwo = () => {
  return (
    <div className="home-main-sections home-section-two" id="donate-section">
      <div className="donate-content">
        <div className="banner-donate-text">
          <div className="banner-donate-text-heading">
            <div>
              Your gift today can help <span>change</span> the life of a
              graduate and their parents
            </div>
          </div>
          <a href="/donate-bent-christy-graduate-assistance-program">
            <button>
              <div className="text">DONATE</div>
              <div className="circle">
                <img src="/icons/heart-white.png" alt="heart" />
                <span className="text-over">TODAY</span>
              </div>
            </button>
          </a>
        </div>

        <div className="banner-donate-text-image">
          <img
            src="./images/home/image19-bg-no-1.webp"
            alt="Donate BCGAP Image"
            fetchpriority="high"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeSectionTwo;
