import { useState, useEffect } from "react";
import "./welcomeAdmin.css";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../userContext";

const WelcomeAdmin = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { currentUser, setCurrentUser } = useUser();

  const baseUrl =
    process.env.REACT_APP_ENV === "production" ? "" : "http://localhost:4010";

  let csrfToken = null;

  async function getCsrfToken() {
    if (!csrfToken) {
      const response = await fetch(`${baseUrl}/api/csrf-token`, {
        credentials: "include",
      });
      const data = await response.json();
      csrfToken = data.csrfToken;
    }
    return csrfToken;
  }

  const handleShowAdminMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = async () => {
    const csrfToken = await getCsrfToken();
    const response = await fetch(`${baseUrl}/api/auth/admin-access/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": csrfToken,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setCurrentUser(null);
      sessionStorage.removeItem("isAuthenticated");
      navigate("/login");
    } else {
      const data = await response.json();
    }
  };
  if (!currentUser) {
    return <p>Please log in to access this page.</p>;
  }

  return (
    <div className="admin-welcome">
      <div className="welcome-group">
        <h3>
          Welcome {currentUser.firstName} {currentUser.lastName}!
        </h3>
        <div className="links">
          <Link className="link" to="/admin-access">
            Applications
          </Link>
          <Link className="link" to="/admin-access-archive">
            Archive
          </Link>
          {currentUser.role === "admin" && (
            <div className="link">
              <Link to="/admin-access-privileges">Privileges</Link>
              <img
                src="https://img.icons8.com/ios/ffffff/100/lock--v1.png"
                alt="lock--v1"
              />
            </div>
          )}
          <div className="link logout" onClick={handleLogout}>
            <img
              src="https://img.icons8.com/external-jumpicon-line-ayub-irawan/ffffff/64/external-_29-user-interface-jumpicon-(line)-jumpicon-line-ayub-irawan.png"
              alt="external-_29-user-interface-jumpicon-(line)-jumpicon-line-ayub-irawan"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeAdmin;
