import "./successPage.css";
import { useState, useEffect } from "react";

const ThankYou = () => {
  return (
    <div className="thank-you-page">
      <div className="thank-you-container">
        <h1>Thank you for your generous donation!</h1>
      </div>
    </div>
  );
};

export default ThankYou;
