import "./homeSection3.css";
import { useRef, useState } from "react";

const HomeSectionThree = () => {
  const [isHoveredPulse, setIsHoveredPulse] = useState({
    0: false,
    1: false,
    2: false,
  });

  const handleMouseEnter = (id) => {
    setIsHoveredPulse((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  return (
    <div className="home-main-sections why-donate">
      <div className="why-donate-title">Ways To Give</div>
      <div className="why-donate-body">
        <div
          className="why-donate-card"
          onMouseEnter={() => handleMouseEnter(0)}
        >
          <div
            className={`image-border ${
              isHoveredPulse[0] ? "ripple1 ripple2" : ""
            } `}
          >
            <div className="why-donate-image">
              <img
                src="./images/home/dollar.webp"
                alt="Donate BCGAP Image"
              />
            </div>
          </div>
          <div className="why-donate-text-wrapper">
            <div className="why-donate-heading">$1 Fundrasing</div>
            <div className="why-donate-text">
              College loan is the second highest consumer debt therefore, we
              believe that if everyone gives $1 to support this cause it will
              help to eliminate college loan burden for million and future
              generation{" "}
            </div>
            <a href="/donate-bent-christy-graduate-assistance-program">
              <button>Read more</button>
            </a>
          </div>
        </div>
        <div
          className="why-donate-card"
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={() => setIsHoveredPulse(false)}
        >
          <div
            className={`image-border ${
              isHoveredPulse[1] ? "ripple1 ripple2" : ""
            } `}
          >
            <div className="why-donate-image">
              <img
                src="./images/home/image103.webp"
                alt="Donate BCGAP Image"
              />
            </div>
          </div>
          <div className="why-donate-text-wrapper">
            <div className="why-donate-heading">Employer Matching</div>
            <div className="why-donate-text">
              Did you know many employers offer corporate giving matching whcih
              can multiply your gift and our impat for college studentts and
              thier parents
            </div>
            <a href="/donate-bent-christy-graduate-assistance-program">
              <button>Read more</button>
            </a>
          </div>
        </div>
        <div
          className="why-donate-card"
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={() => setIsHoveredPulse(false)}
        >
          <div
            className={`image-border ${
              isHoveredPulse[2] ? "ripple1 ripple2" : ""
            } `}
          >
            <div className="why-donate-image">
              <img
                src="./images/home/image105.webp"
                alt="Donate BCGAP Image"
              />
            </div>
          </div>
          <div className="why-donate-text-wrapper">
            <div className="why-donate-heading">
              Stock Gift & Planning Giving
            </div>
            <div className="why-donate-text">
              Making a gift of securities or consider including BCGAP in your
              estate planning offer a unique opportunity for philanthropy, and a
              great future possible for countless college graduates and their
              parents for years to come
            </div>
            <a href="/donate-bent-christy-graduate-assistance-program">
              <button>Read more</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionThree;
