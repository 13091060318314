import "./PageForUndergrads-2.css";
import Bold from "../style/styleBold";
import Red from "../style/styleRed";
import Label from "../style/styleLabel";
import { useState, useRef, useEffect } from "react";
import PopUpPrivacyPolicy from "../popUpPrivacyPolicy";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedVideo } from "@cloudinary/react";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { format } from "@cloudinary/url-gen/actions/delivery";

const PageForUndergrads = () => {
  const [showPopPrivacy, setShowPopPrivacy] = useState(false);
  const [dropdownMenu, setDropDownMenu] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [socialMismatch, setSocialMismatch] = useState(false);
  const applicationAboutRef = useRef(null);
  const applicationDeadlinesRef = useRef(null);
  const applicationFormRef = useRef(null);
  const [showContent, setShowContent] = useState([false, false, false]);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const applicationEssayRef = useRef(null);
  const creditReportRef = useRef(null);
  const loanRepaymentRef = useRef(null);
  const currentIncomeRef = useRef(null);
  const loanServicerRef = useRef(null);
  const loanAgreementRef = useRef(null);
  const transcriptRef = useRef(null);
  const checkboxRef = useRef(null);
  const [confirmSSN, setConfirmSSN] = useState();
  const [application, setApplication] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    email: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    dateOfBirth: "",
    citizenship: "",
    socialSecurity: "",
    collegeAttended: "",
    loanAmount: "",
    loanYear: "",
    collegeMajor: "",
    yearEnrolled: "",
    yearGraduated: "",
    employmentStatus: "",
    grossIncome: "",
    householdSize: "",
    houseOwnedOrRent: "",
    applicationEssay: null,
    creditReport: null,
    loanHistory: null,
    currentIncome: null,
    loanServicer: null,
    loanAgreement: null,
    transcript: null,
  });

  const years = Array.from({ length: 11 }, (_, i) => 2020 + i);

  const toggleDropdown = (dropdownId) => {
    setDropDownMenu((prev) => ({
      ...prev,
      [dropdownId]: !prev[dropdownId],
    }));
  };

  const handleShowContent = (index) => {
    setShowContent((prevShowContent) =>
      prevShowContent.map((item, i) => (i === index ? !item : item))
    );
  };

  const handleChangeSSN = (e) => {
    let ssnValue = e.target.value.replace(/\D/g, "");
    if (ssnValue.length > 9) {
      ssnValue = ssnValue.slice(0, 9); // Limit input to 9 digits
    }
    if (ssnValue.length > 5) {
      ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
    } else if (ssnValue.length > 3) {
      ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
    }

    if (ssnValue !== confirmSSN) {
      setSocialMismatch(true);
    } else {
      setSocialMismatch(false);
    }

    setConfirmSSN(ssnValue);

    if (ssnValue !== application.socialSecurity) {
      setSocialMismatch(true);
    } else {
      setSocialMismatch(false);
    }
  };

  const formatCurrency = (value) => {
    const cleanValue = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = cleanValue.split(".");
    const formattedInteger = integerPart
      ? parseInt(integerPart, 10).toLocaleString("en-US")
      : "";
    const formattedDecimal = decimalPart ? decimalPart.slice(0, 2) : "";
    return formattedDecimal
      ? `${formattedInteger}.${formattedDecimal}`
      : formattedInteger;
  };

  const handleChange = (e) => {
    const { name, type, value, files } = e.target;

    if (name === "socialSecurity") {
      let ssnValue = e.target.value.replace(/\D/g, "");
      if (ssnValue.length > 9) {
        ssnValue = ssnValue.slice(0, 9); // Limit input to 9 digits
      }
      if (ssnValue.length > 5) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
      } else if (ssnValue.length > 3) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
      }

      if (ssnValue !== confirmSSN) {
        setSocialMismatch(true);
      } else {
        setSocialMismatch(false);
      }

      setApplication((prevState) => ({
        ...prevState,
        [name]: ssnValue,
      }));
    } else if (name === "phone") {
      let phoneValue = e.target.value.replace(/\D/g, "");
      if (phoneValue.length > 10) {
        phoneValue = phoneValue.slice(0, 10); // Limit input to 9 digits
      }

      let formattedPhone = phoneValue;

      if (phoneValue.length > 6) {
        formattedPhone = phoneValue.replace(
          /(\d{3})(\d{3})(\d{0,4})/,
          "($1) $2-$3"
        );
      } else if (phoneValue.length > 3) {
        formattedPhone = phoneValue.replace(/(\d{3})(\d{0,3})/, "($1) $2");
      }

      setApplication((prevState) => ({
        ...prevState,
        [name]: formattedPhone,
      }));
    } else if (name === "loanAmount") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: formatCurrency(value),
      }));
    } else if (name === "grossIncome") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: formatCurrency(value),
      }));
    } else if (type === "file") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setApplication((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (confirmSSN !== application.socialSecurity) {
      return;
    }

    const formData = new FormData();

    Object.keys(application).forEach((key) => {
      if (application[key] !== null) {
        formData.append(key, application[key]);
      }
    });

    fetch("/api/undergrad/submit-new-application", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmissionSuccess(true);
        setConfirmSSN("");
        setApplication({
          firstName: "",
          middleName: "",
          lastName: "",
          suffix: "",
          email: "",
          phone: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          dateOfBirth: "",
          citizenship: "",
          socialSecurity: "",
          collegeAttended: "",
          loanAmount: "",
          loanYear: "",
          collegeMajor: "",
          yearEnrolled: "",
          yearGraduated: "",
          employmentStatus: "",
          grossIncome: "",
          householdSize: "",
          houseOwnedOrRent: "",
        });

        if (applicationEssayRef.current) applicationEssayRef.current.value = "";
        if (creditReportRef.current) creditReportRef.current.value = "";
        if (loanRepaymentRef.current) loanRepaymentRef.current.value = "";
        if (currentIncomeRef.current) currentIncomeRef.current.value = "";
        if (loanServicerRef.current) loanServicerRef.current.value = "";
        if (loanAgreementRef.current) loanAgreementRef.current.value = "";
        if (transcriptRef.current) transcriptRef.current.value = "";
        if (checkboxRef.current) {
          checkboxRef.current.checked = false;
        }

        setTimeout(() => {
          fetch("/api/send/send-email-new-application", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message: "New application received",
            }),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return response.text().then((text) => {
                  throw new Error(text);
                });
              }
            })
            .then((data) => {
              console.log("Success:", data);
            })
            .catch((error) => {
              console.error("Error triggering email:", error);
              alert("An error occurred while triggering the email.");
            });
        }, 5000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setDropDownMenu((prevState) => {
        return Object.keys(prevState).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dughixn4l",
    },
  });

  const video = cld
    .video("video_undergrad_1-8_gx2ijc")
    .resize(scale())
    .delivery(quality("auto:good"))
    .delivery(format("auto"));

  return (
    <div className="undergrad-page funding-page">
      <div className="funding-heading">
        Congratulations College Graduates! <br />
        What's Next?
      </div>
      <div className="funding-content">
        <div className="text-image">
          <div className="image">
            <AdvancedVideo
              cldVid={video}
              autoPlay
              muted
              playsInline
              loop
              preload="metadata"
              poster={`https://res.cloudinary.com/dughixn4l/video/upload/so_2/video-public-id.jpg`}
            />
          </div>
          <div className="text">
            <div className="paragraph  intro">
              Congratulations on your remarkable achievement of graduating from
              college!
            </div>
            <div className="paragraph">
              Your perseverance and hard work have opened up numerous
              opportunities, and your future should not be limited by student
              loan repayments. The excitement of graduation may have been
              dampened by the first loan payment. As you start your professional
              journey,{" "}
              <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold> is
              here to ensure that those student loan repayments do not become an
              insurmountable obstacle. If you're struggling with college loan
              repayments, we are here to help ease the burden.
            </div>
            <div className="paragraph">
              Our <Bold>Repayment Program </Bold> is designed to alleviate the
              financial burden faced by many undergraduates and parents that are
              struggling to repay college loans.
            </div>{" "}
            <div className="paragraph">
              Our work is fueled by a passion for education, and being the
              change we need to see to end the college debt crisis. Learn more
              about our mission, our vision, and the opportunity how you can
              empower and help lighten the burden of college debt for you and
              the next generation.
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "2rem" }}
          className="subheading"
          ref={applicationAboutRef}
          onClick={() => handleShowContent(0)}
        >
          BCGAP Repayment Requirements{" "}
          <img
            style={{
              transform: showContent[0] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-about-scholarship"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[0] ? "block" : "none" }}
        >
          <div>
            If you are an undergraduate struggling to keep up with your college
            loan payments, even while working full-time and budgeting carefully,
            our organization is here to help alleviate your financial burden.
            You are encouraged to apply for loan repayment assistance if you
            meet the following criteria:
          </div>
          <div>
            <ul>
              <li>Attended a 4-year college</li>
              <li>
                {" "}
                Completed your degree in 4 years <Red>*</Red>
              </li>
              <li>Experiencing hardship due to student debt repayments</li>
            </ul>
            <div style={{ marginTop: ".5rem" }}></div>
            <Red> * </Red> If personal hardships (such as caring for an ill
            parent, financial need, etc.) delayed your graduation, you are still
            eligible to apply. Please detail your hardship in your application
            materials.
          </div>
        </div>
        <div
          className="subheading"
          ref={applicationDeadlinesRef}
          onClick={() => handleShowContent(1)}
        >
          BCGAP Repayment Documents
          <img
            style={{
              transform: showContent[1] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-deadline"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[1] ? "block" : "none" }}
        >
          To apply for the <Bold>BCGAP Undergraduate Repayment</Bold>, please be
          prepared to submit the following documents:
          <ol>
            <li>Official College Transcript</li>
            <li>Loan Agreement or Promissory Note</li>
            <li>Loan Servicer Statements</li>
            <li>Provide current income and expense </li>
            <li>
              Letter from your employer with Employment status: Full-time.
              Part-time, Laid-off
            </li>
            <li>Current Income Tax and employee pay stub </li>
            <li>Provide governance assistance if laid-off </li>
            <li>Loan repayment history record </li>
            <li>
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score{" "}
            </li>
            <li>Letter from college showing your start and end date </li>
            <li>Application Letter (min. 500 words)</li>
            <ul>
              <div style={{ marginLeft: "-2rem", marginTop: ".5rem" }}>
                <b>In your letter, please discuss:</b>{" "}
              </div>
              <li>What motivated you to pursue a college degree?</li>
              <li>
                Did you complete your degree within four years? If you
                encountered any challenges that delayed your graduation, please
                share the details.
              </li>{" "}
              <li>How did you finance your college education?</li>
              <li>
                What sacrifices did you and/or your family have to make to cover
                the cost of your college degree?
              </li>
              <li>
                If selected, how would the{" "}
                <Bold>
                  Bent Christy Graduate Assistance Program (BCGAP) Repayment
                  Program
                </Bold>{" "}
                assist you in achieving your personal and/or professional goals?
              </li>
              <li>
                Did you engage in any community service or volunteer activities
                during college? If so, please describe what you did and for
                which organization. If not, please explain why.
              </li>
            </ul>
          </ol>
        </div>
        <div
          className="subheading"
          ref={applicationFormRef}
          onClick={() => handleShowContent(2)}
        >
          BCGAP Repayment Application
          <img
            style={{
              transform: showContent[2] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-form"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[2] ? "block" : "none" }}
        >
          Please fill out the form below to submit an application for the{" "}
          <Bold>BCGAP Undergraduate Repayment</Bold>:
          <form onSubmit={handleSubmit}>
            <Label>Student Basic Info:</Label>
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="first-name"
              name="firstName"
              onChange={handleChange}
              value={application.firstName}
              required
            />
            <label htmlFor="mid-initial">Middle Initial</label>
            <input
              type="text"
              id="mid-initial"
              name="middleName"
              value={application.middleName}
              onChange={handleChange}
            />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="last-name"
              name="lastName"
              onChange={handleChange}
              value={application.lastName}
              required
            />
            <label htmlFor="suffix">
              Suffix <Red>*</Red>
            </label>
            <input
              type="text"
              id="suffix"
              name="suffix"
              onChange={handleChange}
              value={application.suffix}
              required
            />

            <Label>Student Contact Info:</Label>
            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              value={application.email}
              required
            />
            <label htmlFor="phone">
              Phone <Red>*</Red>
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              onChange={handleChange}
              value={application.phone}
              required
            />
            <label htmlFor="permanent-address">
              Permanent Address <Red>*</Red>
            </label>
            <input
              type="text"
              id="permanent-address"
              name="address"
              onChange={handleChange}
              value={application.address}
              required
            />
            <label htmlFor="address-2">Address 2 </label>
            <input
              type="text"
              id="address-2"
              name="address2"
              onChange={handleChange}
              value={application.address2}
            />
            <label htmlFor="city">
              City <Red>*</Red>{" "}
            </label>
            <input
              type="text"
              id="city"
              name="city"
              onChange={handleChange}
              value={application.city}
              required
            />
            <label htmlFor="state">
              State <Red>*</Red>
            </label>
            <input
              type="text"
              id="state"
              name="state"
              onChange={handleChange}
              value={application.state}
              required
            />
            <label htmlFor="zip">
              Zip Code <Red>*</Red>
            </label>
            <input
              type="text"
              id="zip"
              name="zip"
              onChange={handleChange}
              value={application.zip}
              required
            />

            <Label>Student Personal Info:</Label>
            <label htmlFor="dob">
              Date of Birth (MM/DD/YYYY) <Red>*</Red>
            </label>
            <input
              type="date"
              id="dob"
              name="dateOfBirth"
              onChange={handleChange}
              value={application.dateOfBirth}
              required
            />
            <label htmlFor="citizenship">
              Citizenship <Red> * </Red>{" "}
            </label>
            <div className="dropdown-menu">
              <input
                type="text"
                id="citizenship"
                name="citizenship"
                onClick={() => {
                  toggleDropdown(1);
                }}
                onChange={handleChange}
                value={application.citizenship}
                readOnly
                required
              />
              {dropdownMenu[1] && (
                <ul className="form-dropdown-list">
                  {["US citizen", "US national", "US permanent resident"].map(
                    (status) => (
                      <li
                        onClick={() => {
                          setApplication((prevState) => ({
                            ...prevState,
                            citizenship: status,
                          }));
                          toggleDropdown(1);
                        }}
                      >
                        {status}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
            <label htmlFor="ssn">
              Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="ssn"
              name="socialSecurity"
              onChange={handleChange}
              value={application.socialSecurity}
              maxLength={11}
              required
            />
            {socialMismatch && (
              <div className="mismatch">
                The Social Security numbers you entered don't match.
              </div>
            )}
            <label htmlFor="confirm-ssn">
              Confirm Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="confirm-ssn"
              name="confirmSSN"
              onChange={handleChangeSSN}
              value={confirmSSN}
              maxLength={11}
              required
            />

            <Label>School and Loan Info:</Label>
            <label htmlFor="college-attended">
              College Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-attended"
              name="collegeAttended"
              onChange={handleChange}
              value={application.collegeAttended}
              required
            />
            <label htmlFor="loan-amount">
              College Loan Amount <Red>*</Red>
            </label>
            <input
              type="text"
              id="loan-amount"
              name="loanAmount"
              value={application.loanAmount}
              onChange={handleChange}
              required
            />
            <label htmlFor="loan-year">
              Year in School Loan Will Help Cover <Red>*</Red>
            </label>
            <input
              type="text"
              id="loan-year"
              name="loanYear"
              value={application.loanYear}
              onChange={handleChange}
              required
            />
            <label htmlFor="major-in-college">
              College Major (and Minor, if applicable) <Red>*</Red>
            </label>
            <input
              type="text"
              id="major-in-college"
              name="collegeMajor"
              value={application.collegeMajor}
              onChange={handleChange}
              required
            />
            <label htmlFor="year-enrolled">
              Year Enrolled <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="year-enrolled"
                name="yearEnrolled"
                value={application.yearEnrolled}
                onChange={handleChange}
                onClick={() => {
                  toggleDropdown(2);
                  if (dropdownMenu[3] === true) {
                    toggleDropdown(3);
                  }
                }}
                readOnly
                required
              />
              {dropdownMenu[2] && (
                <ul className="form-dropdown-list years">
                  {years.map((year) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          yearEnrolled: year,
                        }));
                        toggleDropdown(2);
                      }}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="year-completed">
              Year Completed <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="year-completed"
                name="yearGraduated"
                value={application.yearGraduated}
                onChange={handleChange}
                onClick={() => {
                  toggleDropdown(3);
                }}
                readOnly
                required
              />
              {dropdownMenu[3] && (
                <ul className="form-dropdown-list years">
                  {years.map((year) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          yearGraduated: year,
                        }));
                        toggleDropdown(3);
                        if (dropdownMenu[2] === true) {
                          toggleDropdown(2);
                        }
                      }}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="college-transcript">
              Transcript <Red>*</Red>
            </label>
            <input
              ref={transcriptRef}
              type="file"
              id="college-transcript"
              name="transcript"
              onChange={handleChange}
              required
            />

            <label htmlFor="loan-agreement">
              Loan Agreement or Promissory Note <Red>*</Red>
            </label>
            <input
              ref={loanAgreementRef}
              type="file"
              id="loan-agreement"
              name="loanAgreement"
              onChange={handleChange}
              required
            />

            <label htmlFor="loan-statement">
              Loan Servicer Statements <Red>*</Red>
            </label>
            <input
              ref={loanServicerRef}
              type="file"
              id="loan-statement"
              name="loanServicer"
              onChange={handleChange}
              required
            />

            <label htmlFor="application-letter">
              Application Letter (See the instructions above) <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-letter"
              name="applicationEssay"
              onChange={handleChange}
              required
            />

            <Label>Proof of Financial Need Info: </Label>
            <label htmlFor="current-income">
              Current Income and Expense Documentation <Red>*</Red>
            </label>

            <input
              ref={currentIncomeRef}
              type="file"
              id="current-income"
              name="currentIncome"
              onChange={handleChange}
              required
            />

            <label htmlFor="employment-status">
              Employment Status: <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="employment-status"
                name="employmentStatus"
                value={application.employmentStatus}
                onClick={() => {
                  toggleDropdown(4);
                }}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[4] && (
                <ul className="form-dropdown-list">
                  {["Full-time", "Part-time", "Laid-off"].map((stat) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          employmentStatus: stat,
                        }));
                        toggleDropdown(4);
                      }}
                    >
                      {stat}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="gross-income">
              Gross Annual Income <Red>*</Red>
            </label>
            <input
              type="text"
              id="gross-income"
              name="grossIncome"
              value={application.grossIncome}
              onChange={handleChange}
              required
            />

            <label htmlFor="loan-repayment-history">
              Loan Repayment History Record <Red>*</Red>
            </label>
            <input
              ref={loanRepaymentRef}
              type="file"
              id="loan-repayment-history"
              name="loanHistory"
              onChange={handleChange}
              accept=".pdf,application/pdf"
              required
            />

            <label htmlFor="credit-report">
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score <Red>*</Red>
            </label>
            <input
              ref={creditReportRef}
              type="file"
              id="credit-report"
              name="creditReport"
              onChange={handleChange}
              accept=".pdf,application/pdf"
              required
            />

            <label htmlFor="household-size">
              Household Size <Red>*</Red>
            </label>
            <input
              type="text"
              id="household-size"
              name="householdSize"
              value={application.householdSize}
              onChange={handleChange}
              required
            />

            <label htmlFor="house-status">
              House Own / Rent <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="house-status"
                name="houseOwnedOrRent"
                value={application.houseOwnedOrRent}
                onClick={() => {
                  toggleDropdown(5);
                }}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[5] && (
                <ul className="form-dropdown-list">
                  {["Owned", "Rent"].map((house) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          houseOwnedOrRent: house,
                        }));
                        toggleDropdown(5);
                      }}
                    >
                      {house}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="policy" className="policy">
              <input ref={checkboxRef} type="checkbox" name="policy" />
              <span>
                By checking this box you are consent to being entered for a
                chance to win our Bent Christy Graduate Assistance Program
                (BCGAP) scholarship, processing of your Personal Information in
                Accordance with our{" "}
                <u
                  className="privacy-link"
                  onClick={() => {
                    setShowPopPrivacy(true);
                  }}
                >
                  Privacy Policy
                </u>
                .
              </span>
            </label>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
      {showPopPrivacy && (
        <div className="pop-privacy-policy">
          <PopUpPrivacyPolicy setShowPopPrivacy={setShowPopPrivacy} />
        </div>
      )}
    </div>
  );
};

export default PageForUndergrads;
