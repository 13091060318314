import "./homeSection4.css";
import { useState, useEffect } from "react";
import Bold from "../style/styleBold";

const HomeSectionFour = () => {
  const windowWidth = window.innerWidth;
  const [contentHeight, setContentHeight] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [titleBreak, setTitleBreak] = useState(false);

  const handleShowMore = () => {
    setContentHeight(!contentHeight);
  };

  return (
    <div className="home-main-sections section-four">
      {/*      <div className="bg-image">
        <img src="./images/home/home-grad-15.jpg" alt="bent-christy" />  
      </div>
 */}

      {/*
        <div className="area-d"></div>
        <div className="area-x">
          <div className="img-item-blank"></div>
        </div>
        <div className="area-a">
          <div className="img-wrap-shadow">
            <div className="img-item">
              <img src="./images/home/grace_photo_1.jpg" alt="bent-christy" />
            </div>
          </div>
        </div>

        <div className="area-b">
          <div className="img-wrap-shadow">
            <div className="img-item">
              <img
                src="./images/home/christina_photo_2.jpg"
                alt="bent-christy"
              />
            </div>
          </div>
        </div>
        <div className="area-e"></div>
        <div className="area-c">
          <div className="img-wrap-shadow">
            <div className="img-item">
              <img src="./images/home/lita_photo_1.jpg" alt="bent-christy" />
            </div>
          </div>
        </div>
        <div className="area-z">
          <div className="img-item-blank"></div>
        </div>
       

        <div className="photo-wrapper">
          <img
            src="./images/home/grace_photo4.jpg"
            alt="photo-leadershipt_grace"
          />
        </div>

        <div className="photo-wrapper">
          <img
            src="./images/home/christina_photo_2.jpg"
            alt="photo-leadershipt_christina"
          />
        </div>
      </div>
       */}

      <div className="image-about">
        <div className="image-container">
          <img src="./images/home/home-about-8.webp" alt="About Us" />
        </div>
      </div>
      <div className="home-section-four-block-wrapper">
        <div className="heading">
          Who We Are {titleBreak && <br />} & What We Do{" "}
        </div>

        <div className="content" style={{ maxHeight: contentHeight && "100%" }}>
          <Bold> Bent Christy Graduate Assistance Program (BCGAP)</Bold> is a
          non-profit organization founded in 2019 with the mission of providing
          financial assistance to undergraduates and parents struggling with
          college loan repayment. In the landscape of higher education, where
          dreams are forged and futures shaped, the journey often begins with a
          significant investment – college education. For many, this investment
          manifests in the form of student loans. However, for some graduates
          and parents, the burden of these loans can become overwhelming,
          overshadowing the joy of achievement with the stress of financial
          strain. Our vision is fueled by her passion for education and a
          commitment to ending the college debt crisis.
        </div>
      </div>
    </div>
  );
};

export default HomeSectionFour;
