import "./PageContact.css";
import Red from "../style/styleRed";
import Bold from "../style/styleBold";
import { useState } from "react";

const ContactUs = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const baseUrl =
    process.env.REACT_APP_ENV === "production" ? "" : "http://localhost:4010";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${baseUrl}/api/send/send-email-contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(contactForm), // Send the contactForm state as JSON
      });

      console.log("Response status:", response.status); // Log status code

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        setEmailSent(true);
        setEmailText(
          "Thank you for contacting us! We will be in touch with you shortly."
        );

        setContactForm({
          name: "",
          email: "",
          message: "",
        });
      } else {
        setEmailSent(true);
        setEmailText(
          "Something went wrong. Please try again or email us: info@bcgap.org."
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="contact-us-page">
      <div
        className="alert-sent-message"
        style={{ display: emailSent ? "flex" : "none" }}
      >
        <div>{emailText}</div>
        <button
          onClick={() => {
            setEmailSent(false);
          }}
        >
          OK
        </button>
      </div>
      <div className="contact-us-heading">Contact Us</div>
      <div className="contact-us-section">
        <div className="images-header-all">
          <div className="image-group">
            <div className="image-header">
              <img
                src="./images/contact/contact4.webp"
                alt="contact-us-photo"
              />
            </div>
            <div className="image-header">
              <img
                src="./images/contact/contact8.webp"
                alt="contact-us-photo"
              />
            </div>
            <div className="image-header">
              <img
                src="./images/contact/contact1.webp"
                alt="contact-us-photo"
              />
            </div>
            <div className="image-header">
              <img
                src="./images/contact/contact3.webp"
                alt="contact-us-photo"
              />
            </div>
          </div>
        </div>
        <div className="contact-details">
          <div className="icon">
            <img
              src="https://img.icons8.com/parakeet-line/96/new-post.png"
              alt="new-post"
            />
            <Bold>Email: </Bold>{" "}
            <a href="mailto:info@bcgap.org">info@bcgap.org</a>
          </div>

          <div className="icon">
            <img
              src="https://img.icons8.com/ios/100/phone-message.png"
              alt="phone-message"
            />
            <Bold> Phone: </Bold> (609) 912-1023
          </div>
        </div>

        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">
              Full Name <Red> * </Red>
            </label>
            <input
              type="text"
              name="name"
              value={contactForm.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="email">
              Email <Red> * </Red>
            </label>
            <input
              type="email"
              name="email"
              value={contactForm.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="message">
              Message <Red> * </Red>
            </label>
            <textarea
              name="message"
              rows="5"
              value={contactForm.message}
              onChange={handleChange}
              required
            />
            <input type="submit" value="Send" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
