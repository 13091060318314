import "./PageArchive.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import WelcomeAdmin from "./welcomeAdmin";
import { useUser } from "../userContext";

const PageArchive = () => {
  const [archiveSchool, setArchiveSchool] = useState(null);
  const [archiveUndergrad, setArchiveUndergrad] = useState(null);
  const [archiveParents, setArchiveParents] = useState(null);
  const { currentUser } = useUser();

  const baseUrl =
    process.env.REACT_APP_ENV === "production" ? "" : "http://localhost:4010";

  const getCsrfToken = async () => {
    const response = await fetch(`${baseUrl}/api/csrf-token`, {
      credentials: "include",
    });
    const data = await response.json();
    return data.csrfToken;
  };

  const fetchSchoolAchivedApplications = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/archive/high-school/get-archive`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setArchiveSchool(data);
      } else {
        console.error("Failed to fetch the archived applications");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUndergradAchivedApplications = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/archive/undergrad/get-archive`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setArchiveUndergrad(data);
      } else {
        console.error("Failed to fetch the archived applications");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchParentAchivedApplications = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/archive/parents/get-archive`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setArchiveParents(data);
      } else {
        console.error("Failed to fetch the archived applications");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSchoolAchivedApplications();
    fetchUndergradAchivedApplications();
    fetchParentAchivedApplications();
  }, []);

  const handleSchoolUnarchive = async (id) => {
    try {
      const csrfToken = await getCsrfToken();
      const response = await fetch(
        `${baseUrl}/api/archive/high-school/unarchive`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify({ id }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        fetchSchoolAchivedApplications();
      } else {
        const errorData = await response.json();
        console.error("Failed to unarchive:", errorData);
      }
    } catch (error) {
      console.error("Error during unarchive request:", error);
    }
  };

  const handleUndergradUnarchive = async (id) => {
    try {
      const csrfToken = await getCsrfToken();
      const response = await fetch(
        `${baseUrl}/api/archive/undergrad/unarchive`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
          body: JSON.stringify({ id }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        fetchUndergradAchivedApplications();
      } else {
        const errorData = await response.json();
        console.error("Failed to unarchive:", errorData);
      }
    } catch (error) {
      console.error("Error during unarchive request:", error);
    }
  };

  const handleParentUnarchive = async (id) => {
    try {
      const csrfToken = await getCsrfToken();
      const response = await fetch(`${baseUrl}/api/archive/parents/unarchive`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ id }),
      });
      if (response.ok) {
        const data = await response.json();
        fetchParentAchivedApplications();
      } else {
        const errorData = await response.json();
        console.error("Failed to unarchive:", errorData);
      }
    } catch (error) {
      console.error("Error during unarchive request:", error);
    }
  };

  return (
    <>
      <div className="archive-page">
        <div className="archive-container">
          <h1>Archived Applications</h1>
          <div style={{ marginTop: "1.5rem" }}></div>
          <h2>High School Applications</h2>

          <table>
            <tbody>
              {!archiveSchool || archiveSchool.length <= 0 ? (
                <div>You don't have any archived high school applications.</div>
              ) : (
                archiveSchool.map((application, index) => {
                  const name = `${application.firstName.toLowerCase()}-${application.lastName.toLowerCase()}`;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {application.firstName} {application.lastName || ""}
                      </td>
                      <td>
                        <div className="button-group">
                          <div className="view-btn">
                            <Link
                              to={`/show-applicant/high-school/${application.school_applicant_id}/${name}`}
                            >
                              View
                            </Link>
                          </div>
                          <div
                            className="un-archive-btn"
                            onClick={() =>
                              handleSchoolUnarchive(
                                application.school_applicant_id
                              )
                            }
                          >
                            Unarchive
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div style={{ marginTop: "1.5rem" }}></div>
          <h2>Undergraduate Applications</h2>
          <table>
            <tbody>
              {!archiveUndergrad || archiveUndergrad.length <= 0 ? (
                <div>
                  You don't have any archived undergraduate applications.
                </div>
              ) : (
                archiveUndergrad.map((application, index) => {
                  const name = `${application.firstName.toLowerCase()}-${application.lastName.toLowerCase()}`;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {application.firstName} {application.lastName || ""}
                      </td>
                      <td>
                        <div className="button-group">
                          <div className="view-btn">
                            <Link
                              to={`/show-applicant/undergrad/${application.undergrad_applicant_id}/${name}`}
                            >
                              View
                            </Link>
                          </div>
                          <div
                            className="un-archive-btn"
                            onClick={() =>
                              handleUndergradUnarchive(
                                application.undergrad_applicant_id
                              )
                            }
                          >
                            Unarchive
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div style={{ marginTop: "1.5rem" }}></div>
          <h2>Parent Applications</h2>
          <table>
            <tbody>
              {!archiveParents || archiveParents.length <= 0 ? (
                <div>You don't have any archived parents applications.</div>
              ) : (
                archiveParents.map((application, index) => {
                  const name = `${application.parentFirstName.toLowerCase()}-${application.parentLastName.toLowerCase()}`;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {application.parentFirstName}{" "}
                        {application.parentLastName || ""}
                      </td>
                      <td>
                        <div className="button-group">
                          <div className="view-btn">
                            <Link
                              to={`/show-applicant/parent/${application.parent_applicant_id}/${name}`}
                            >
                              View
                            </Link>
                          </div>
                          <div
                            className="un-archive-btn"
                            onClick={() =>
                              handleParentUnarchive(
                                application.parent_applicant_id
                              )
                            }
                          >
                            Unarchive
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PageArchive;
