import { Link } from "react-router-dom";
import WelcomeAdmin from "../adminPages/welcomeAdmin";
import { useUser } from "../userContext";
import "./adminAccessNav.css";

const AdminAccessNav = () => {
  const { currentUser, setCurrentUser } = useUser();
  return (
    <div className="admin-link">
      <WelcomeAdmin currentUser={currentUser} />
    </div>
  );
};

export default AdminAccessNav;
