import { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  // Lazy-load user info from sessionStorage
  const [currentUser, setCurrentUser] = useState(
    () => JSON.parse(sessionStorage.getItem("currentUser")) || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => JSON.parse(sessionStorage.getItem("isAuthenticated")) || false
  );

  const [loading, setLoading] = useState(false);

  const baseUrl =
    process.env.NODE_ENV === "production" ? "" : "http://localhost:4010";

  useEffect(() => {
    // Only ping the auth endpoint if we don't already have a currentUser.
    if (currentUser === null && isAuthenticated === false) {
      return;
    }

    setLoading(true);

    const checkAuthStatus = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/auth/status`, {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setCurrentUser(data.user);
          setIsAuthenticated(true);
          console.log("SESSION USER SAVED");
          sessionStorage.setItem("currentUser", JSON.stringify(data.user));
          sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
        } else {
          setCurrentUser(null);
          setIsAuthenticated(false);
          console.log("SESSION USER NOT SAVED");
          sessionStorage.removeItem("currentUser");
          sessionStorage.removeItem("isAuthenticated");
        }
      } catch (error) {
        console.error("Failed to check auth status", error);
        setCurrentUser(null);
        setIsAuthenticated(false);
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("isAuthenticated");
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <img src="./icons/loading2.gif" alt="Loading..." />
      </div>
    );
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        isAuthenticated,
        setIsAuthenticated,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
