import "./homeSection5.css";
import { Link, useNavigate } from "react-router-dom";

const HomeSectionFive = () => {
  const navigate = useNavigate();

  return (
    <div
      className="home-main-sections"
      id="bcgap-apply-for-repayment-and-scholarship"
    >
      <div className="home-section-five">
        <div className="home-section-five-col">
          <img
            src="/images/home/image501.webp"
            alt="Apply for the Undergraduate and Parent Repayment and High-School Scholarship "
          />
          <div className="overlay"></div>
          <div className="title"> </div>
          <div className="content">
            <div>
              Apply for Parent & Undergraduate Loan Repayment, and High School
              Senior Scholarship!
            </div>
          </div>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="col-wrapper">
            <div className="title">High School Senior Scholarship</div>
            <div className="content">
              As you approach the exciting milestone of high school graduation
              and prepare for college, we offer high school senior scholarship
              and college loan repayment assistance programs to help you pay for
              college.
              <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
                <div className="apply">
                  <img
                    src="/icons/arrow-up-right-494f58.png"
                    alt="How to apply?"
                  />
                  <span className="how-to-apply">HOW TO APPLY</span>
                </div>
              </a>
            </div>
          </div>

          {/*
         

          */}
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="col-wrapper">
            <div className="title">Undergraduate Loan Repayment </div>
            <div className="content">
              Are you having difficulty repaying your college loans? We provide
              financial assistance specifically for graduates who completed
              their degrees within four years and are experiencing repayment
              challenges.
              <a href="/bent-christy-graduate-assistance-program-for-college-students">
                <div className="apply">
                  <img
                    src="/icons/arrow-up-right-494f58.png"
                    alt="How to apply?"
                  />
                  <span className="how-to-apply">HOW TO APPLY</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="col-wrapper">
            <div className="title">Parent Loan Repayment</div>
            <div className="content">
              Are you struggling to repay the loans taken out for your child's
              education? Bent Christy Graduate Assistance Program (BCGAP)
              provides financial assistance to parents facing college loan
              repayment hardships.
              <a href="/bent-christy-graduate-assistance-program-for-parents">
                <div className="apply">
                  <img
                    src="/icons/arrow-up-right-494f58.png"
                    alt="How to apply?"
                  />
                  <span className="how-to-apply">HOW TO APPLY</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionFive;
