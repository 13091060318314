import "./PageForHighSchoolStudents-2.css";
import Bold from "../style/styleBold";
import Red from "../style/styleRed";
import styleArrowForm from "../style/styleArrowForm";
import { useState, useRef, useEffect } from "react";
import PopUpPrivacyPolicy from "../popUpPrivacyPolicy";
import SelectText from "../components/selectTextInput";
import baseUrl from "../baseUrl";

const PageForHighSchoolStudents = () => {
  const applicationAboutRef = useRef(null);
  const applicationFormRef = useRef(null);
  const [showContent, setShowContent] = useState([false, false, false]);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmSSN, setConfirmSSN] = useState();
  const [socialMismatch, setSocialMismatch] = useState(false);
  const academicRecordsRef = useRef(null);
  const applicationEssayRef = useRef(null);
  const recommendationLetterRef = useRef(null);
  const checkboxRef = useRef(null);
  const [dropdownMenu, setDropDownMenu] = useState({
    0: false,
    1: false,
  });
  const [showPopPrivacy, setShowPopPrivacy] = useState(false);
  const [application, setApplication] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    socialSecurity: "",
    address: "",
    highSchoolAttended: "",
    yearGraduated: "",
    citizenship: "",
    collegeAccepted: "",
    academicRecords: null,
    applicationEssay: null,
    recommendationLetter: null,
    comments: "",
  });

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setDropDownMenu((prevState) => {
        return Object.keys(prevState).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleShowContent = (index) => {
    setShowContent((prevShowContent) =>
      prevShowContent.map((item, i) => (i === index ? !item : item))
    );
  };

  const handleChange = (e) => {
    const { name, type, value, files } = e.target;

    if (name === "socialSecurity") {
      let ssnValue = e.target.value.replace(/\D/g, "");
      if (ssnValue.length > 9) {
        ssnValue = ssnValue.slice(0, 9); // Limit input to 9 digits
      }
      if (ssnValue.length > 5) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
      } else if (ssnValue.length > 3) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
      }

      if (ssnValue !== confirmSSN) {
        setSocialMismatch(true);
      } else {
        setSocialMismatch(false);
      }

      setApplication((prevState) => ({
        ...prevState,
        [name]: ssnValue,
      }));
    } else if (name === "phone") {
      let phoneValue = e.target.value.replace(/\D/g, "");
      if (phoneValue.length > 10) {
        phoneValue = phoneValue.slice(0, 10); // Limit input to 9 digits
      }

      let formattedPhone = phoneValue;

      if (phoneValue.length > 6) {
        formattedPhone = phoneValue.replace(
          /(\d{3})(\d{3})(\d{0,4})/,
          "($1) $2-$3"
        );
      } else if (phoneValue.length > 3) {
        formattedPhone = phoneValue.replace(/(\d{3})(\d{0,3})/, "($1) $2");
      }

      setApplication((prevState) => ({
        ...prevState,
        [name]: formattedPhone,
      }));
    } else if (type === "file") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: files[0], // For file inputs
      }));
    } else {
      setApplication((prevState) => ({
        ...prevState,
        [name]: value, // For text inputs
      }));
    }
  };

  const handleChangeSSN = (e) => {
    let ssnValue = e.target.value.replace(/\D/g, "");
    if (ssnValue.length > 9) {
      ssnValue = ssnValue.slice(0, 9); // Limit input to 9 digits
    }
    if (ssnValue.length > 5) {
      ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
    } else if (ssnValue.length > 3) {
      ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
    }

    if (ssnValue !== confirmSSN) {
      setSocialMismatch(true);
    } else {
      setSocialMismatch(false);
    }

    setConfirmSSN(ssnValue);

    if (ssnValue !== application.socialSecurity) {
      setSocialMismatch(true);
    } else {
      setSocialMismatch(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(application).forEach((key) => {
      if (application[key] !== null) {
        formData.append(key, application[key]);
      }
    });

    fetch(`${baseUrl}/api/high-school/submit-new-application`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setSuccessMessage("Thank you for submitting your application!");
        setSubmissionSuccess(true);
        setApplication({
          firstName: "",
          lastName: "",
          dateOfBirth: "",
          phone: "",
          socialSecurity: "",
          email: "",
          address: "",
          highSchoolAttended: "",
          yearGraduated: "",
          citizenship: "",
          collegeAccepted: "",
          academicRecords: null,
          applicationEssay: null,
          recommendationLetter: null,
          comments: "",
        });
        setConfirmSSN("");
        if (academicRecordsRef.current) academicRecordsRef.current.value = "";
        if (applicationEssayRef.current) applicationEssayRef.current.value = "";
        if (recommendationLetterRef.current)
          recommendationLetterRef.current.value = "";
        if (checkboxRef.current) {
          checkboxRef.current.checked = false;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSubmissionSuccess(true);
        setSuccessMessage(
          "Something went wrong. Please try again or contact us at info@bcgap.org."
        );
      });
  };

  return (
    <div className="high-school-page funding-page">
      <div
        className="submission-success"
        style={{ display: submissionSuccess ? "flex" : "none" }}
      >
        <div>{successMessage}</div>
        <button
          onClick={() => {
            setSubmissionSuccess(false);
          }}
        >
          OK
        </button>
      </div>
      <div className="funding-heading">
        Bent Christy Graduate Assistance Program (BCGAP) Foundation Scholarship
        Program
      </div>
      <div className="funding-content">
        <div className="text-image">
          <div className="text">
            <div className="image">
              <img
                src="./images/for-students/h-student2.webp"
                alt="high-school-student-photo"
              />
            </div>
            <div className="paragraph intro">
              Great job on reaching the exciting milestone of high school
              graduation!
            </div>
            <div className="paragraph">
              As you begin the next chapter of your education, we want to inform
              you how{" "}
              <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold> can
              assist with college loan repayment. We recognize that managing
              student loan repayments after graduation can be stressful. BCGAP
              believes that financial circumstances should never be a barrier to
              pursuing a college education. That is why we offer financial
              assistance to qualified graduates who completed their degrees
              within four years and are struggling with loan repayment. Here is
              how we can help as you embark on the next phase of your
              educational journey.
            </div>{" "}
            <div className="paragraph">
              Our work is fueled by a passion for education, and being the
              change we need to see to end the college debt crisis. Learn more
              about our mission, our vision, and the opportunity how you can
              empower and help lighten the burden of college debt for you and
              the next generation.
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "2rem" }}
          className="subheading"
          ref={applicationAboutRef}
          onClick={() => handleShowContent(0)}
        >
          Scholarship Overview{" "}
          <img
            style={{
              transform: showContent[0] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/00c8dc/100/expand-arrow--v1.png"
            alt="show-about-scholarship"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[0] ? "block" : "none" }}
        >
          <div>
            The <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold>{" "}
            scholarship is open to high school seniors and recent graduates
            (within one year of graduation) who have a GPA of 2.5 or higher and
            plan to attend a four-year university. Scholarship recipients are
            chosen based on their proven leadership skills, and dedication to
            service in their local communities. Preference is given to
            applicants from single-parent households who show a strong desire to
            create positive change in the world.
            <div style={{ marginTop: "1.5rem" }}></div>
            $1,000 Bent Christy Graduate Assistance Program (BCGAP)
            <div style={{ marginTop: "1.5rem" }}></div>
            <Bold> Scholarship Current Deadline:</Bold> July 31, 2024{" "}
          </div>
        </div>

        <div
          className="subheading"
          ref={applicationFormRef}
          onClick={() => handleShowContent(2)}
        >
          Application Form
          <img
            style={{
              transform: showContent[2] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/00c8dc/100/expand-arrow--v1.png"
            alt="show-application-form"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[2] ? "block" : "none" }}
        >
          <div>
            Please fill out the form below to submit your scholarship
            application.
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="first-name"
              name="firstName"
              onChange={handleChange}
              value={application.firstName}
              required
            />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="last-name"
              name="lastName"
              onChange={handleChange}
              value={application.lastName}
              required
            />
            <label htmlFor="dateOfBirth">
              Date of Birth <Red>*</Red>
            </label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              onChange={handleChange}
              value={application.dateOfBirth}
              required
            />

            <label htmlFor="socialSecurity">
              Social Security Number <Red>*</Red>
            </label>
            <input
              type="text"
              id="socialSecurity"
              name="socialSecurity"
              onChange={handleChange}
              value={application.socialSecurity}
              required
            />
            {socialMismatch && (
              <div className="mismatch">
                The Social Security numbers you entered don't match.
              </div>
            )}
            <label htmlFor="confirmSSN">
              Confirm Social Security Number <Red>*</Red>
            </label>
            <input
              type="text"
              id="confirmSSN"
              name="confirmSSN"
              onChange={handleChangeSSN}
              value={confirmSSN}
              required
            />
            <label htmlFor="citizenship">
              Citizenship
              <Red>*</Red>
            </label>
            <SelectText
              variable={application}
              setVariable={setApplication}
              fieldName={"citizenship"}
              options={["US citizen", "US national", "US resident"]}
              compColor={"00c8dc"}
            />
            {/*
            <div className="dropdown-menu">
              <input
                type="text"
                id="citizenship"
                name="citizenship"
                onChange={handleChange}
                value={application.citizenship}
                onClick={() => {
                  toggleDropdown(1);
                  if (dropdownMenu[0] === true) {
                    toggleDropdown(0);
                  }
                }}
                readOnly
                required
              />

              {dropdownMenu[1] && (
                <ul className="form-dropdown-list">
                  {["US citizen", "US national", "US permanent resident"].map(
                    (status) => (
                      <li
                        onClick={() => {
                          setApplication((prevState) => ({
                            ...prevState,
                            citizenship: status,
                          }));
                          toggleDropdown(1);
                        }}
                      >
                        {status}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
*/}

            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              value={application.email}
              required
            />
            <label htmlFor="phone">
              Phone <Red>*</Red>
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              onChange={handleChange}
              value={application.phone}
              required
            />

            <label htmlFor="address">
              Address <Red>*</Red>
            </label>
            <input
              type="address"
              id="address"
              name="address"
              onChange={handleChange}
              value={application.address}
              required
            />
            <label htmlFor="high-school-attended">
              High School Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="high-school-attended"
              name="highSchoolAttended"
              onChange={handleChange}
              value={application.highSchoolAttended}
              required
            />
            <label htmlFor="year-graduated">
              Year of Graduation <Red>*</Red>
            </label>

            <SelectText
              variable={application}
              setVariable={setApplication}
              fieldName={"yearGraduated"}
              options={Array.from(
                { length: 2035 - 2018 + 1 },
                (_, index) => 2018 + index
              )}
              compColor={"00c8dc"}
            />

            {/*
            <div className="dropdown-menu">
              <input
                type="text"
                id="year-of-graduation"
                name="yearGraduated"
                value={application.yearGraduated}
                onClick={() => {
                  toggleDropdown(0);
                  if (dropdownMenu[1] === true) {
                    toggleDropdown(1);
                  }
                }}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[0] && (
                <ul className="form-dropdown-list years">
                  {yearsList.map((year) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          yearGraduated: year,
                        }));
                        toggleDropdown(0);
                      }}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          */}
            <label htmlFor="college-accepted">
              College Accepted <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-accepted"
              name="collegeAccepted"
              onChange={handleChange}
              value={application.collegeAccepted}
              required
            />
            <label htmlFor="application-letter">
              Academic Records <Red>*</Red>
            </label>
            <input
              type="file"
              id="academic-records"
              name="academicRecords"
              accept=".pdf,application/pdf"
              onChange={handleChange}
              ref={academicRecordsRef}
              required
            />
            <label htmlFor="application-essay">
              Application Essay <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-essay"
              name="applicationEssay"
              accept=".pdf,application/pdf"
              onChange={handleChange}
              ref={applicationEssayRef}
              required
            />
            <label htmlFor="recommendation-letters">
              Letter of Recommendation <Red>*</Red>
            </label>
            <input
              type="file"
              id="recommendation-letter"
              name="recommendationLetter"
              onChange={handleChange}
              accept=".pdf,application/pdf"
              ref={recommendationLetterRef}
              required
            />
            <label htmlFor="comments">Comments</label>
            <textarea
              type="text"
              id="comments"
              name="comments"
              onChange={handleChange}
              value={application.comments}
            />
            <label htmlFor="policy" className="policy">
              <input type="checkbox" name="policy" ref={checkboxRef} required />
              <span>
                By checking this box you are consent to being entered for a
                chance to win our Bent Christy Graduate Assistance Program
                (BCGAP) scholarship, processing of your Personal Information in
                Accordance with our{" "}
                <u
                  className="privacy-link"
                  onClick={() => setShowPopPrivacy(true)}
                >
                  Privacy Policy
                </u>
                .
              </span>
            </label>
            <input type="submit" value="Submit" />
          </form>
        </div>
        {showPopPrivacy && (
          <div className="pop-privacy-policy">
            <PopUpPrivacyPolicy setShowPopPrivacy={setShowPopPrivacy} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageForHighSchoolStudents;
