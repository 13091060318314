import { useState, useEffect, useRef } from "react";
import Bold from "../style/styleBold";
import { useParams, Link } from "react-router-dom";
import "./PageAdminIndividual.css";
import WelcomeAdmin from "./welcomeAdmin";
import { useUser } from "../userContext";

const PageAdminIndividualParent = () => {
  const { id } = useParams();
  const [currentApplicant, setCurrentApplicant] = useState();
  const { currentUser } = useUser();
  const downloadLinkRef = useRef(null);

  useEffect(() => {
    const fetchApplicant = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/parents/get-individual/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (response.ok) {
          console.log("Parent applicant fetched.");
          const data = await response.json();
          setCurrentApplicant(data);
        } else {
          console.error("Applicant not fetched.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchApplicant();
  }, [id]);

  const baseUrl =
    process.env.REACT_APP_ENV === "production" ? "" : "http://localhost:4010";

  const downloadPDF = async (applicantId) => {
    try {
      // Fetch the PDF from the backend
      const response = await fetch(
        `${baseUrl}/api/parents/download-pdf/${applicantId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Update the href of the <a> element dynamically
        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = url;
          downloadLinkRef.current.download = `${currentApplicant.parentFirstName}_${currentApplicant.parentLastName}_Application_Package.pdf`;

          // Programmatically trigger the click event to start the download
          downloadLinkRef.current.click();
        }
      } else {
        console.error("Failed to download PDF");
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const downloadFile = async (applicantId, fileId, fileName) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/parents/download-file/${applicantId}/${fileId}`,
        {
          method: "GET",
          credentials: "include", // Include cookies
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element for download
        const a = document.createElement("a");
        a.href = url;
        a.download = `${currentApplicant.parentLastName}_${fileName}.pdf`; // Name for the file
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download file:", await response.json());
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  return (
    <>
      <div className="individual-applicant-page">
        <table>
          <tbody>
            {currentApplicant && (
              <>
                <tr>
                  <td className="name">
                    <Bold>Parent Name: </Bold>
                  </td>
                  <td className="name">
                    {currentApplicant.parentSuffix}{" "}
                    {currentApplicant.parentFirstName}{" "}
                    {currentApplicant.parentMidInitial}{" "}
                    {currentApplicant.parentLastName}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Bold>Parent Date of Birth: </Bold>
                  </td>
                  <td>
                    {" "}
                    {new Date(
                      currentApplicant.parentDateOfBirth
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </td>
                </tr>
                {currentApplicant.parentSocialSecurity && (
                  <tr>
                    <td>
                      <Bold>Parent Social Security Number: </Bold>
                    </td>
                    <td>{currentApplicant.parentSocialSecurity}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <Bold>Parent Citizenship: </Bold>
                  </td>
                  <td>{currentApplicant.parentCitizenship}</td>
                </tr>
                {currentApplicant.parentEmail && (
                  <tr>
                    <td>
                      <Bold>Parent Email: </Bold>
                    </td>
                    <td>{currentApplicant.parentEmail}</td>
                  </tr>
                )}
                {currentApplicant.parentPhone && (
                  <tr>
                    <td>
                      <Bold>Parent Phone: </Bold>
                    </td>
                    <td>{currentApplicant.parentPhone}</td>
                  </tr>
                )}
                {currentApplicant.parentAddress && (
                  <tr>
                    <td>
                      <Bold>Parent Permanent Address: </Bold>
                    </td>
                    <td>
                      {currentApplicant.parentAddress}{" "}
                      {currentApplicant.parentAddress2},{" "}
                      {currentApplicant.parentCity},{" "}
                      {currentApplicant.parentState}{" "}
                      {currentApplicant.parentZip}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <Bold>Student Name: </Bold>
                  </td>
                  <td>
                    {currentApplicant.studentFirstName}{" "}
                    {currentApplicant.studentMidInitial}{" "}
                    {currentApplicant.studentLastName}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Bold>Student Date of Birth: </Bold>
                  </td>
                  <td>
                    {" "}
                    {new Date(
                      currentApplicant.studentDateOfBirth
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Bold>Student Citizenship: </Bold>
                  </td>
                  <td>{currentApplicant.studentCitizenship}</td>
                </tr>
                {currentApplicant.studentSocialSecurity && (
                  <tr>
                    <td>
                      <Bold>Student Social Security Number: </Bold>
                    </td>
                    <td>{currentApplicant.studentSocialSecurity}</td>
                  </tr>
                )}
                {currentApplicant.studentAddress && (
                  <tr>
                    <td>
                      <Bold>Student Address (if different from parent): </Bold>
                    </td>
                    <td>
                      {currentApplicant.studentAddress
                        ? currentApplicant.studentAddress
                        : "None"}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <Bold>Student College Attended: </Bold>
                  </td>
                  <td>{currentApplicant.collegeAttended}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Student College Major: </Bold>
                  </td>
                  <td>{currentApplicant.collegeMajor}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Student Year Enrolled: </Bold>
                  </td>
                  <td>{currentApplicant.yearEnrolled}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Student Year Graduated: </Bold>
                  </td>
                  <td>{currentApplicant.yearGraduated}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>College Loan Amount: </Bold>
                  </td>
                  <td>{currentApplicant.loanAmount}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Year in School Loan Will Help Cover: </Bold>
                  </td>
                  <td>{currentApplicant.loanYear}</td>
                </tr>

                <tr>
                  <td>
                    <Bold>Parent Employment Status: </Bold>
                  </td>
                  <td>{currentApplicant.employmentStatus}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Parent Gross Annual Income: </Bold>
                  </td>
                  <td>{currentApplicant.grossIncome}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Household Size: </Bold>
                  </td>
                  <td>{currentApplicant.householdSize}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>House Own / Rent: </Bold>
                  </td>
                  <td>{currentApplicant.houseOwnedOrRent}</td>
                </tr>
                {currentApplicant.files &&
                  currentApplicant.files.map((file) => (
                    <tr>
                      <td>
                        <Bold>{file.fileAlias}: </Bold>
                      </td>
                      <td>
                        <Link
                          onClick={() =>
                            downloadFile(
                              currentApplicant.parent_applicant_id,
                              file.fileID,
                              file.fileAlias
                            )
                          }
                        >
                          Download
                        </Link>
                      </td>
                    </tr>
                  ))}
                {(currentUser.role === "admin" ||
                  currentUser.role === "employee") && (
                  <tr>
                    <td className="pdf-download" colSpan="2">
                      <button
                        onClick={() =>
                          downloadPDF(currentApplicant.parent_applicant_id)
                        }
                      >
                        DOWNLOAD
                      </button>
                      {downloadLinkRef && (
                        <a ref={downloadLinkRef} style={{ display: "none" }}>
                          Open PDF
                        </a>
                      )}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PageAdminIndividualParent;
