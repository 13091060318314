import React from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "./userContext";

export const PrivateRoute = ({ children }) => {
  const { currentUser, isAuthenticated } = useUser();
  // Allow access only if authenticated and role is either admin or volunteer or employee
  return (
    isAuthenticated &&
    (currentUser?.role === "admin" ||
      currentUser?.role === "employee" ||
      currentUser?.role === "volunteer") &&
    children
  );
};

export const PrivateRouteAdmin = ({ children }) => {
  const { currentUser, isAuthenticated } = useUser();

  if (!isAuthenticated || !currentUser || currentUser?.role !== "admin") {
    return (
      <div className="no-privileges">
        <p>
          <a href="/admin-access">Return to Manage Applications</a>
        </p>
      </div>
    );
  }
  return children;
};
