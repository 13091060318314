import "./footer.css";
import { ReactComponent as LogoSVGFooter } from "./assets/logo-equal.svg";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-col rights">
        <div className="logo">
          <a href="/">
            <LogoSVGFooter className="logo-svg-footer" />
          </a>
        </div>
        <div className="vision">
          Bent Christy Graduate Assistance Program (BCGAP) provides financial
          assistance to undergraduates and parents to allow them to have a
          debt-free life from the burden of college loans.
        </div>
        <div className="social-media">
          <a href="https://www.facebook.com/bcgap/" target="_blank">
            <img
              src="https://img.icons8.com/ios-filled/ffffff/100/facebook-new.png"
              alt="Facebook"
            />
          </a>
          <a href="https://www.instagram.com/bcgap/" target="_blank">
            <img
              src="https://img.icons8.com/ios-filled/100/ffffff/instagram-new--v1.png"
              alt="Instagram"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/bent-christy-graduate-assistance-program-bcgap/"
            target="_blank"
          >
            <img
              src="https://img.icons8.com/ios-filled/ffffff/100/linkedin.png"
              alt="LinkedIn"
            />
          </a>
        </div>
      </div>

      <div className="footer-col">
        <div className="contacts">
          <div className="header">Links</div>
          <div className="wrapper">
            <a href="/">Home</a>
            <a href="/about-bent-christy-graduate-assistance-program">
              About Us
            </a>
            <a href="/bent-christy-graduate-assistance-program-our-team">
              Our Leadership
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-college-students">
              BCGAP Undegraduate Repayment
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-parents">
              BCGAP Parent Repayment
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
              BCGAP High School Senior Scholarship
            </a>
            <a href="/bent-christy-graduate-assistance-program-news-stories">
              News & Stories
            </a>
            <a href="/bent-christy-graduate-assistance-program-become-a-partner">
              Become a Partner
            </a>
            <a href="/bent-christy-graduate-assistance-program-volunteer-opportunities">
              Volunteer
            </a>
            <a href="/bent-christy-graduate-assistance-program-bcgap-store">
              Shop BCGAP
            </a>
            <a href="/donate-bent-christy-graduate-assistance-program">
              Donate
            </a>
            <a href="/bent-christy-graduate-assistance-program-contact-us">
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <div className="footer-col">
        <div className="contacts">
          <div className="header"> Contact Us</div>
          <div className="wrapper">
            <div className="email">
              <img
                style={{ opacity: ".7" }}
                src="https://img.icons8.com/parakeet-line/ffffff/96/new-post.png"
                alt="new-post"
              />
              <a href="mailto:info@bcgap.org">info@bcgap.org</a>
            </div>

            <div className="phone">
              <img
                style={{ opacity: ".7" }}
                src="https://img.icons8.com/ios/ffffff/100/phone-message.png"
                alt="phone-message"
              />
              (609) 912-1023
            </div>

            <div className="message">
              <img
                style={{ opacity: ".7" }}
                src="https://img.icons8.com/parakeet-line/ffffff/96/chat.png"
                alt="chat"
              />
              <a href="/bent-christy-graduate-assistance-program-contact-us">
                Message us!
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="rights-reserved">
        <span>©2019 by Bent Christy Graduate Assistance Program (BCGAP)</span>
        <span>
          <a href="https://nchernysheva.com" target="_blank">
            Developed by Natalia Chernysheva
          </a>
        </span>
      </div>
      <div class="admin-page-link">
        <a href="/login">
          <img
            src="https://img.icons8.com/external-tal-revivo-light-tal-revivo/ffffff/96/external-computer-system-locked-with-a-passcode-heavy-authentication-protocol-web-light-tal-revivo.png"
            alt="admin-pages-restricted-access"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
