import { useEffect, useState } from "react";
import "./PageTeam.css";
import Bold from "../style/styleBold";
import Typewriter from "../style/typewriterEffect";
import { useNavigate } from "react-router-dom";

const PageTeam = () => {
  const [photoVisible, setPhotoVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="team-page">
      <div className="team-header">
        <Typewriter text="being the change we need to see" />
      </div>
      <div className="glyph">
        <img
          width="64"
          height="64"
          src="https://img.icons8.com/hatch/64/expand-arrow.png"
          alt="expand-arrow"
        />
      </div>
      <div className="team-intro">
        <div className="leadership-subheader">Our Leadership</div>
        <span>
          Meet the dynamic team leading the{" "}
          <Bold>Bent Christy Graduate Assistance Program’s (BCGAP)</Bold>{" "}
          efforts to create a future where financial barriers or the weight of
          student debt no longer prevent individuals from reaching their full
          potential.
        </span>
      </div>
      <div className="team-container">
        <div className="photo-block">
          <a href="/bent-christy-graduate-assistance-program-our-team-member/0">
            <div className="team-photo">
              <img
                src="./images/home/grace_photo4.webp"
                alt="Grace Bent"
              />
            </div>
            <div className="photo-caption">
              <div className="photo-caption-name">Grace Bent</div>
              <div className="photo-caption-role">
                Founder and Chief Executive Officer{" "}
              </div>
              <div className="read-more">
                <img
                  src="https://img.icons8.com/windows/96/right.png"
                  alt="Read about Grace Bent"
                />
              </div>
            </div>
          </a>
        </div>
        <div className="photo-block">
          <a href="/bent-christy-graduate-assistance-program-our-team-member/1">
            <div className="team-photo">
              <img
                style={{ objectPosition: "0 0" }}
                src="./images/home/christina_photo_2.webp"
                alt="Christina Emile"
              />
            </div>
            <div className="photo-caption">
              <div className="photo-caption-name">Christina Emile</div>
              <div className="photo-caption-role">
                Executive Director and Chief Operating Officer{" "}
              </div>
              <div className="read-more">
                <img
                  src="https://img.icons8.com/windows/96/right.png"
                  alt="Read about Christina Emile"
                />
              </div>
            </div>
          </a>
        </div>
        <div className="photo-block">
          <a href="/bent-christy-graduate-assistance-program-our-team-member/2">
            <div className="team-photo">
              <img
                style={{ objectPosition: "0 0" }}
                src="./images/home/lita_photo_2.webp"
                alt="Lita Graham"
              />
            </div>
            <div className="photo-caption">
              <div className="photo-caption-name">Lita Graham</div>
              <div className="photo-caption-role">
                Director of Chief Creative Officer{" "}
              </div>
              <div className="read-more">
                <img
                  src="https://img.icons8.com/windows/96/right.png"
                  alt="Read about Lita Graham"
                />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageTeam;
